import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
  Slide,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import React, { useCallback } from 'react'
import useLang from '../hooks/useLang'
import usePermissions from '../hooks/usePermissions'
import Config from '../utils/Config'

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  ),
)

const PermissionDialog = () => {
  const { commons, lang } = useLang()
  const { dialog, cleanDialog, realAsk, loading, cancel } = usePermissions()

  const renderContent = useCallback(() => {
    if (!dialog) return null

    const key = dialog as keyof typeof commons.permissions
    return <DialogContentText>{commons.permissions[key][lang]}</DialogContentText>
  }, [dialog, commons, lang])

  return (
    <Dialog open={!!dialog} TransitionComponent={Transition} keepMounted onClose={() => {}}>
      <DialogTitle>{Config.NAME}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      {loading && <LinearProgress />}
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            cancel(dialog)
            cleanDialog()
          }}
          color="primary"
          style={{ flex: 1 }}>
          {commons.general.cancel[lang]}
        </Button>
        <Button onClick={realAsk} color="primary" style={{ flex: 1 }}>
          {commons.general.accept[lang]}
        </Button>
      </DialogActions>
      <Divider />
      {/* <Button onClick={cleanDialog} size="small">
        {commons.general.notShowAgain[lang]}
      </Button> */}
    </Dialog>
  )
}

export default PermissionDialog
