import { LinearProgress, makeStyles } from '@material-ui/core'
import { createContext, memo, ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ExhibitionProps } from '../components/ExhibitionItem'
import { MarkerProps as SampleProps } from '../components/Map/DetailCanvas'
import { NewsProps } from '../components/NewsItem'
import Urls from './Urls'
import useAxios, { CallProps } from './useAxios'

type TotalResponse = { countries: number; samples: number }

interface StateProps {
  total: TotalResponse
  samples: SampleProps[]
  exhibitions: ExhibitionProps[]
  news: NewsProps[]
}

interface ProviderProps {
  children: ReactNode
}

const defValue: StateProps = {
  total: { samples: 0, countries: 0 },
  samples: [],
  news: [],
  exhibitions: [],
}

type ContextProps = {
  services: StateProps
  callTotal: CallProps<TotalResponse>
  callSamples: CallProps<SampleProps[]>
  callExhibitions: CallProps<ExhibitionProps[]>
  callNews: CallProps<NewsProps[]>
}

export const ServicesContext = createContext<ContextProps>({
  services: defValue,
  callTotal: async () => ({}),
  callSamples: async () => ({}),
  callExhibitions: async () => ({}),
  callNews: async () => ({}),
})

export const ServicesProvider = memo(({ children }: ProviderProps) => {
  const { location } = useHistory()
  const isAdmin = location.pathname.includes('admin') || location.pathname.includes('detail')
  const [total, loadingTotal, callTotal] = useAxios<TotalResponse>({
    onInit: !isAdmin ? { url: Urls.muestrasTotal } : undefined,
  })
  const [samples, loadingSamples, callSamples] = useAxios<SampleProps[]>({
    onInit: !isAdmin ? { url: Urls.muestras } : undefined,
  })
  const [exhibitions, loadingExhibitions, callExhibitions] = useAxios<ExhibitionProps[]>({
    onInit: !isAdmin ? { url: Urls.exhibitions } : undefined,
  })
  const [news, loadingNews, callNews] = useAxios<NewsProps[]>({
    onInit: !isAdmin ? { url: Urls.news } : undefined,
  })

  const [services, setServices] = useState<StateProps>(defValue)

  useEffect(() => {
    if (samples) {
      setServices((act) => ({ ...act, samples }))
    }
  }, [samples])

  useEffect(() => {
    if (total) {
      setServices((act) => ({ ...act, total }))
    }
  }, [total])

  useEffect(() => {
    if (news) {
      setServices((act) => ({ ...act, news }))
    }
  }, [news])

  useEffect(() => {
    if (exhibitions) {
      setServices((act) => ({ ...act, exhibitions }))
    }
  }, [exhibitions])

  const isLoading = loadingSamples || loadingTotal || loadingNews || loadingExhibitions
  const classes = useClasses()

  return (
    <ServicesContext.Provider value={{ services, callSamples, callTotal, callNews, callExhibitions }}>
      {children}
      {isLoading && <LinearProgress className={classes.loading} />}
    </ServicesContext.Provider>
  )
})

const useClasses = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
}))
