import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DialogState, PermissionContext } from '../utils/PermissionContext'
import { useCamera } from './useLang'

interface Props {
  onInit: DialogState
}

const usePermissions = (props?: Props) => {
  const onInitRef = useRef(false)
  const { setOpened } = useCamera()
  const { onInit } = props || {}
  const [state, setPermissions, setDialog] = useContext(PermissionContext)
  const { dialog, coords, ...permissions } = state
  const [loading, setLoading] = useState(false)

  const askForPermission = useCallback(
    async (name: PermissionName) => {
      setLoading(true)
      if (!navigator.permissions) {
        setLoading(false)
        return
      }
      const status = await navigator.permissions.query({ name })
      if (status.state === 'prompt') {
        setDialog(name)
      } else if (status.state === 'granted') {
        setPermissions((act) => ({ ...act, [name]: 'granted' }))
      }
      setLoading(false)
    },
    [setDialog, setPermissions],
  )

  const cleanDialog = useCallback(() => {
    setDialog(null)
  }, [setDialog])

  const cancel = useCallback(
    (name: DialogState) => {
      setPermissions((act) => ({ ...act, [name as any]: 'cancel' }))
    },
    [setPermissions],
  )

  /*
  const getCurrentPosition = useCallback(
    (type: DialogState) => {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setPermissions((act) => ({
            ...act,
            [type!!]: 'granted',
            coords: { latitude: coords.latitude.toString(), longitude: coords.longitude.toString() },
          }))
          setDialog(null)
        },
        (err) => {
          setDialog(null)
        },
      )
    },
    [setDialog, setPermissions],
  )
*/
  const realAsk = useCallback(() => {
    if (dialog === 'geolocation') {
      navigator.geolocation.getCurrentPosition(
        (e) => {
          setPermissions((act) => ({ ...act, [dialog]: 'granted' }))
          setDialog(null)
        },
        (err) => {
          setPermissions((act) => ({ ...act, [dialog]: 'denied' }))
          setDialog(null)
          console.log(err)
        },
      )
    }
    if (dialog === 'camera') {
      navigator.getUserMedia(
        { audio: false, video: true },
        (succ) => {
          setPermissions((act) => ({ ...act, [dialog]: 'granted' }))
          setDialog(null)
        },
        (err) => {
          setPermissions((act) => ({ ...act, [dialog]: 'denied' }))
          setDialog(null)
          setOpened(false)
          console.log(err)
        },
      )
    }
  }, [dialog, setDialog, setPermissions, setOpened])

  useEffect(() => {
    if (!onInitRef.current && onInit) {
      askForPermission(onInit)
      onInitRef.current = true
    }
  }, [onInit, askForPermission])

  useEffect(() => {
    if (!dialog) setLoading(false)
  }, [dialog])

  return {
    askForPermission,
    dialog,
    permissions,
    cleanDialog,
    loading,
    realAsk,
    cancel,
  }
}

export default usePermissions
