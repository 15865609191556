import { useCallback, useContext } from 'react'
import Config from '../utils/Config'
import { LangContext, LangType } from '../utils/LangContext'

interface AvailableProps {
  id: LangType
  title: string
}

const availableLangs: AvailableProps[] = [
  { id: 'es', title: 'Español' },
  { id: 'en', title: 'English' },
]

const dev = 'Julián Lionti y Mariano Rey'
export const commons = {
  mainMenu: {
    call: { es: 'Llamar', en: 'Call' },
    mail: { es: 'Mandar mail', en: 'Send an email' },
    facebook: { es: 'Ver nuestro Facebook', en: 'Go to Facebook' },
    twitter: { es: 'Ver nuestro Twitter', en: 'Go to Twitter' },
    instagram: { es: 'Ver nuestro Instagram', en: 'Go to Instagram' },
  },
  menu: {
    home: { es: 'Inicio', en: 'Home' },
    newSample: { es: 'Agregar muestra VIRTUAL', en: 'Add VIRTUAL sample' },
    about: { es: 'Acerca de la obra', en: 'About the work' },
    map: { es: 'Mapa', en: 'Map' },
    aboutArtist: { es: 'Acerca del Artista', en: 'About the artist' },
    exhibitions: { es: 'Exposiciones', en: 'Exhibitions' },
    news: { es: 'Noticias', en: 'News' },
    joinUs: { es: 'Sumate a la obra FÍSICA', en: 'Join the PHYSICAL work' },
    aliances: { es: 'Alianzas', en: 'Aliances' },
  },
  permissions: {
    blocked: {
      es: 'Se bloquearon los permisos. Se deben desbloquear desde el navegador',
      en: 'Permissions were blocked. They must be unlocked from the browser',
    },
    camera: {
      es: '🎞 Para poder subir la muestra es necesario que nos des permiso para usar la cámara',
      en: '🎞 In order to upload the sample it is necessary that you give us permission to use the camera',
    },
    geolocation: {
      es: '🌍 Para poder ubicar la muestra es necesario que nos des permiso para usar tu ubicación',
      en:
        '🌍 In order to locate the sample it is necessary that you give us permission to use your location',
    },
  },
  developed: { es: `Desarrollado por ${dev} ✌🏼`, en: `Developed by ${dev} ✌🏼` },
  mapfilters: {
    intro: (total: number) => ({
      es: `Encuentre su botella entre las ${total} que ya componen la obra`,
      en: `Find your bottle among the  ${total} that already make up the work`,
    }),
    fullName: { es: 'Nombre y/o apellido', en: 'Name and/or surname' },
    fromDate: { es: 'Fecha desde', en: 'From date' },
    toDate: { es: 'Fecha hasta', en: 'To date' },
    clean: { es: 'Limpar', en: 'Clean' },
    filter: { es: 'Filtrar', en: 'Filter' },
  },
  aboutAqua: ({ countries, samples }: { countries: number; samples: number }) => ({
    es: `La obra de arte <b>AQUA PLANETAE</b> es un “work in progress” que nació el 22 de marzo de 2012, Día Mundial del Agua, y gracias a la participación de cientos de personas, hoy está compuesta por ${samples} muestras de agua de ${countries} países de los 6 continentes.`,
    en: `The AQUA PLANETAE work of art is a “work in progress” that was born on March 22, 2012, World Water Day, and thanks to the participation of hundreds of people, today it is made up of ${samples} water samples from ${countries} countries of the 6 continents.`,
  }),

  formSample: {
    newSample: { es: 'Agregar muestra', en: 'New sample' },
    steps: {
      es:
        'Hola! Gracias por colaborar con nuestro proyecto de AquaPlanetae. Para poder cargar una muestra en necesario completar los siguientes pasos.',
      en:
        'Hello! Thank you for collaborating with our AquaPlanetae project. In order to load a sample in need to complete the following steps.',
    },
    name: { es: 'Nombre', en: 'Name' },
    latitude: { es: 'Latitud', en: 'Latitude' },
    longitude: { es: 'Longitud', en: 'Longitude' },
    country: { es: 'Pais', en: 'Country' },
    city: { es: 'Ciudad', en: 'City' },
    date: { es: 'Fecha', en: 'Date' },
  },
  general: {
    cancel: { es: `Cancelar`, en: `Cancel` },
    accept: { es: 'Aceptar', en: 'Accept' },
    error: { es: 'Ocurrió un error', en: 'Error ocurred' },
    notShowAgain: { es: 'No preguntar más', en: 'Do not ask again' },
    filters: {
      show: { es: 'Mostrar filtros', en: 'Show filters' },
      hide: { es: 'Ocultar filtros', en: 'Hide filters' },
    },
    moreInfo: { es: 'Más información', en: 'More information' },
    changeLanguage: { es: 'Cambiar idioma', en: 'Change language' },
    next: { es: 'Siguiente', en: 'Next' },
    back: { es: 'Atras', en: 'Back' },
    finish: { es: 'Terminar', en: 'Finish' },
    preview: { es: 'Vista previa', en: 'Preview' },
    goToMap: { es: 'Abrir mapa', en: 'Go to map' },
    detail: { es: 'Ver detalle', en: 'See detail' },
    copy: { es: 'Copiar link', en: 'Copy link' },
    copied: { es: 'Link copiado al portapapeles', en: 'Link copied to Clipboard' },
    download: { es: 'Descargar PDF', en: 'Download File' },
  },
  home: {
    samples: { es: 'Muestras', en: 'Samples' },
    countries: { es: 'Países', en: 'Countries' },
    continents: { es: 'Continentes', en: 'Continents' },
  },
  newSample: {
    intro: {
      es: `Hola! Gracias por colaborar con nuestro proyecto de <b>${Config.NAME}</b>. Para poder cargar
      una muestra en necesario completar los <b>siguientes pasos.</b>`,
      en: `Hello! Thank you for collaborating with our <b>${Config.NAME}</b> project. To be able to load a sample requires completing the <b>following steps.</b>`,
    },

    location: { es: 'Ubicación', en: 'Location' },
    image: { es: 'Imagen', en: 'Image' },
    data: { es: 'Datos', en: 'Personal data' },

    locationInfo: {
      es: 'La botella se guardará en esta ubicacion',
      en: 'The bottle will be stored in this location',
    },
    selectCounty: { es: 'Elegir país', en: 'Select Country' },
    selectCity: { es: 'Elegir ciudad', en: 'Select City' },
    obtainLocation: { es: 'Obtener ubicación', en: 'Obtain location' },

    changeImage: { es: 'Subir foto distinta', en: 'Take another image' },
    selfie: { es: 'Sacarse una Selfie', en: 'Take a Selfie' },
    gallery: { es: 'De la galería', en: 'From gallery' },
    infoImage: {
      es: 'Subí tu foto con la muestra de agua',
      en: 'Upload your photo with the water sample',
    },
    uploaded: { es: 'Foto cargada!', en: 'Image uploaded!' },

    name: { es: 'Nombre', en: 'Name' },
    nameInput: { es: 'Ingrese su nombre', en: 'Enter your name' },
    messageInput: { es: 'Ingrese un mensaje', en: 'Enter a message' },
    dateInput: { es: 'Ingrese la fecha de carga', en: 'Enter load date' },
    fromSocial: { es: 'Obtener de red social', en: 'From Google or Facebook' },
    byHand: { es: 'Ingresar a mano', en: 'By hand' },
    withFacebook: { es: 'Ingresar con facebook', en: 'With facebook' },
    withGoogle: { es: 'Ingresar con google', en: 'With google' },
    infoLogin: {
      es: 'Para terminar ingresá tu nombre y mensaje',
      en: 'To finish enter your name and message',
    },
  },

  about: [
    {
      es:
        'Carlos Montani, el artista que le da vida a la obra expresa su agradecimiento a quienes se han sumado al proyecto, ayudando a generar conciencia de la importancia del agua para la vida.',
      en:
        'Carlos Montani, the artist that brings this artwork to life, wishes to thank those that have joined the project thus helping him to build awareness on the importance of water for life.',
    },
    {
      es:
        'Buscando expandir las posibilidades y generar un proyecto artístico colaborativo y descentralizado a nivel mundial, se incorpora para el 9° aniversario de la obra una nueva vertiente virtual llamada “Custodia el Agua”, en la que cada persona alrededor del mundo podrá tomar un rol protagónico sumándose a través de las redes y siendo los propios custodios de la muestra de agua que recolectaron. Es importante que cada una de ellas sea de situaciones, momentos y lugares trascendentes para cada colaborador, que tengan su propia historia y puedan contarla.',
      en:
        'Seeking to evolve and expand the possibilities of communicating this message, a new collaborative and global project is created on its 9th anniversary: “ Safeguard Water”. People around the world can become a protagonist, through the social networks and become the guardian of the sample they themselves collected. It is important that each sample is collected at moments or events that are significant to the participants and that involve a story that can be told.',
    },
    {
      es:
        'Actualmente se puede participar de <b>AQUA PLANETAE</b> de dos maneras: ya sea recolectando agua y enviándola al artista para sumar a la obra física o custodiándola personalmente y sumándose a la obra virtual a través de Instagram.',
      en:
        'So at present, you can <b>take part</b> in <b>AQUA PLANETAE</b> either by collecting the water sample and sending it for the artist to add it to the physical display or by guarding your sample personally and be a part of virtual AQUA PLANETAE on Instagram.',
    },
    {
      es:
        '<b>AQUA PLANETAE</b> estuvo expuesta en CSPS Hall (Cedar Rapids, Ohio, USA), Museo del Castillo de Phentes (Ginebra, Suiza), Affordable Art Fair (México), SESC Belenzinho (San Pablo, Brasil), Palacio Vaticano (Roma, Italia), Centro Cultural Konex (Buenos Aires, Argentina), Usina del Arte, Embajada Británica de Buenos Aires, Teatro Colón (Buenos Aires, Argentina), Manzana de las Luces, Museo Sívori, (Buenos Aires, Argentina), Museo Casa Histórica de la Independencia (San Miguel de Tucumán, Argentina), entre otros.',
      en:
        '<b>AQUA PLANETAE</b> was exhibited at CSPS Hall (Cedar Rapids, Ohio, USA), Phentes Castle Museum (Geneva, Switzerland), Affordable Art Fair (Mexico), SESC Belenzinho (San Pablo, Brazil), Paulo VI Auditorium (Vatican), Konex Cultural Center (Buenos Aires, Argentina), Usina del Arte (Buenos Aires, Argentina), British Embassy in Buenos Aires, Colón Theater (Buenos Aires, Argentina), Manzana de las Luces, Sívori Museum, (Buenos Aires, Argentina), Historical House Museum of Independence (San Miguel de Tucumán, Argentina), among others.',
    },
    {
      es:
        'Tomó parte de eventos relacionados con la sustentabilidad y el cuidado de la vida y del medio ambiente como el Congreso Internacional del Agua "Watershed" en la Ciudad del Vaticano, el 4° Foro Nacional de Responsabilidad Social Empresaria, el Kick off de las actividades anuales del Consejo Empresario Argentino para el desarrollo sostenible (CEADS), el Green Film Festival, TEDxUCA, y seleccionada por las Naciones Unidas para ser parte del Programa "ONU - Agua".',
      en:
        '<b>AQUA PLANETAE</b> took part in events related to sustainability and care for life and the environment such as the International Water Congress "Watershed" in Vatican City, the 4th National Forum of Corporate Social Responsibility, the Kick off of the annual activities of the Argentine Business Council for Sustainable Development (CEADS), the Green Film Festival, TEDxUCA, and selected by the United Nations to be part of the "UN - Water" Program.',
    },
  ],
  joinUs: {
    intro: {
      es: '<b>AQUA PLANETAE</b> es un "work in progress" y tu participación es fundamental.',
      en: '<b>AQUA PLANETAE</b> is a "work in process" and your participation is essential.',
    },
    join: {
      es:
        'Si querés <b>ser parte</b> te pido me envíes una muestra de unos 100 cm3 del agua de la canilla o grifo de tu casa o lugar de trabajo. Podes envasarla en una botella pequeña de gaseosa o agua mineral previamente enjuagada con la misma agua y despacharla por correo a mi taller:',
      en:
        'During the Congress each lecturer, such as you, will <b>add a sample of water</b> of their place of origin/residence during the anniversary celebration. If you wish to be part of the project I am asking you to send a sample of 100 cm3 / 3.5 fluid onza (less than a glass of water) of tap water from your home or work place. You can bottle it in a soda or mineral water bottle that you wash with the same water you are collecting and send it by mail to my studio:',
    },
    sent: {
      es:
        'El agua que me envíes será envasada en un frasco esterilizado de vidrio personalizado, por lo que también me debes enviar la siguiente información:',
      en:
        'Water you send me will be bottled in a custom sterilized glass bottle, because of that I ask you to give me the following information:',
    },
    info: [
      { es: 'Nombre y apellido', en: 'Name and surname' },
      { es: 'Fecha cuando tomaste la muestra', en: 'Date when you took the sample' },
      {
        es: 'Nombre de la ciudad y provincia o distrito donde tomaste la muestra',
        en: 'City and state or province where you take the sample',
      },
      { es: 'Latitud y longitud en formato decimal', en: 'Latitude and longitude in decimal format' },
    ],
    thanks: {
      es: 'Muchas gracias por ser parte de AQUA PLANETAE.',
      en: `Thank you to be part of AQUA PLANETAE.`,
    },
    last: {
      es: 'Cualquier duda, consulta, estoy a tu disposición.',
      en: `If you need to ask me anything, do not hesitate to do it. I'd be glad to answer.`,
    },
  },
  aliances: {
    intro: [
      {
        es:
          '<b>AQUA PLANETAE</b> es un "work in progress" y las alianzas estratégicas con instituciones nacionales e internacionales que con su gestión suman valor al cuidado del medio ambiente son fundamentales para su difusión y crecimiento.',
        en:
          '<b>AQUA PLANETAE</b> is a "work in progress". Thus, strategic alliances with national and international institutions whose actions add to environmental care are essential to its diffusion and growth.',
      },
      {
        es:
          '<b>AQUA PLANETAE</b> es una excelente oportunidad de integrar el arte con el cuidado del medio ambiente ofreciendo oportunidades innovadoras para la puesta en marcha de acciones de responsabilidad social corporativa, imagen institucional, programas educativos, eventos relacionados al desarrollo sostenible, congresos, exposiciones y demás actividades que las instituciones llevan adelante, sinergizando de este modo la estrategia de comunicación de su institución.',
        en: `<b>AQUA PLANETAE</b> is an excellent opportunity to merge art and environmental care by offering innovative opportunities for the setup of corporate social responsibility actions, institutional image, educational programs, sustainable development gatherings, congresses, exhibitions and other events an institution can organize. This synergy will favor the company's communicational strategy.`,
      },
      {
        es:
          'La obra es desde su concepción una instalación itinerante por lo que está preparada para trasladarla a cualquier lugar del mundo.',
        en:
          'The piece was conceived as an itinerant installation, i.e. it can be transported to any location in the world.',
      },
      {
        es: 'Hasta el momento <b>se han sumado</b> a <b>AQUA PLANETAE</b> las siguientes:',
        en: 'So far, <b>these organizations and institutions</b> have taken part in the initiative:',
      },
    ],
    organizations: {
      title: {
        es: 'Organizaciones e instituciones:',
        en: 'Organizations and institutions',
      },
      list: [
        {
          es: 'ONU (Programa de Naciones Unidas para el Medio Ambiente)',
          en: 'UN (UN Program for Environment)',
        },
        {
          es: 'ONU (Programa 2005 – 2015 "Decenio del Agua")',
          en: 'UN (2005 -2015 Water Decade Program)',
        },
        {
          es: 'San Luis Agua (III Congreso Internacional del Agua)',
          en: 'San Luis Agua (III International Water Congress)',
        },
        {
          es: 'Nuevos Aires (4° Foro Nacional de RSE)',
          en: 'Nuevos Aires (4th National Corporate Social Responsibility Forum)',
        },
        {
          es: 'Green Film Fest',
          en: 'Green Film Fest',
        },
        {
          es: 'INCUCAI',
          en: 'INCUCAI',
        },
        {
          es: 'Ministerio de Cultura de la Ciudad de Buenos Aires',
          en: 'Ministry of Culture of Buenos Aires City, Argentina',
        },
        {
          es: 'Ministerio de Relaciones Exteriores y Culto de Argentina',
          en: 'Ministry of Foreign Affairs and Worship of Argentina',
        },
        {
          es: `Legion Arts (Exposición 'Trouble the water' Ohaio – USA)`,
          en: 'Legion Arts ("Trouble the water" Exhibition, Ohio – USA)',
        },
        {
          es: 'Misión Eucarística del Bicentenario',
          en: 'Eucharistic Mission of the Bicentennial',
        },
      ],
    },
    companies: {
      title: {
        es: 'Empresas',
        en: 'Companies',
      },
      list: [
        {
          es: 'Mexichem Argentina',
          en: 'Mexichem Argentina',
        },
        {
          es: 'Limpiolux',
          en: 'Limpiolux',
        },
        {
          es: 'Bolsa de Comercio de Buenos Aires',
          en: 'Buenos Aires Stock Exchange',
        },
        {
          es: 'CEADS (Consejo Empresario Argentino para el Desarrollo Sostenible)',
          en: 'CEADS (Argentine Entrepreneur Group for Sustainable Development)',
        },
      ],
    },
    education: {
      title: {
        es: 'Instituciones Educativas',
        en: 'Educational Institutions',
      },
      list: [
        {
          es: 'Instituto Santa Brígida',
          en: `St Brigid's School`,
        },
        {
          es: 'Instituto San Francisco de Asís',
          en: 'San Francisco de Asís Institute',
        },
        {
          es: 'Instituto River Plate',
          en: 'River Plate School',
        },
      ],
    },
    final: {
      es:
        '<b>En conjunto</b> podremos <b>co-crear</b> la actividad que mejor se adapte a los intereses de las partes, consúltenos a ',
      en:
        '<b>Together</b> we can <b>co-create</b> the activity that best suits our interest, consult us at ',
    },
  },
  admin: {
    samples: { es: 'Muestras', en: 'Samples' },
    exhibitions: { es: 'Exibiciones', en: 'Exhibitions' },
    news: { es: 'Noticias', en: 'News' },
    users: { es: 'Usuarixs', en: 'Users' },
    logout: { es: 'Cerrar sesión', en: 'Logout' },
  },
}

const useLang = () => {
  const [translation, setTranslation] = useContext(LangContext)

  const setLang = useCallback(
    (lang: LangType) => {
      setTranslation((act) => ({ ...act, lang }))
      localStorage.setItem('lang', lang)
    },
    [setTranslation],
  )

  return { setLang, lang: translation.lang, availableLangs, commons }
}

export const useResponsive = () => {
  const [{ isMobile }] = useContext(LangContext)
  return isMobile
}

export const useCamera = () => {
  const [{ cameraOpened }, setConfig] = useContext(LangContext)

  const setOpened = useCallback(
    (cameraOpened: boolean) => {
      setConfig((acc) => ({ ...acc, cameraOpened }))
    },
    [setConfig],
  )

  return { isOpen: cameraOpened, setOpened }
}

export const useScroll = () => {
  const [{ hasScrolled }, setConfig] = useContext(LangContext)

  const setHasScrolled = useCallback(
    (hasScrolled) => {
      setConfig((acc) => ({ ...acc, hasScrolled }))
    },
    [setConfig],
  )

  return { hasScrolled, setHasScrolled }
}

export default useLang
