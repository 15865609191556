import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import PermissionDialog from '../components/PermissionDialog'
import { useSnackbar } from 'notistack'
type Status = '' | 'granted' | 'denied' | 'prompt' | 'cancel'

interface ProviderProps {
  children: ReactNode
}

interface PermissionState {
  geolocation: Status
  camera: Status
  coords?: { latitude: string; longitude: string }
}

const defValue: PermissionState = {
  camera: '',
  geolocation: '',
}
export type Permissions = PermissionName

export type DialogState = Permissions | null

const defDialog: DialogState = null

type ContextProps = [
  PermissionState & { dialog: DialogState },
  Dispatch<SetStateAction<PermissionState>>,
  Dispatch<SetStateAction<DialogState>>,
]

export const PermissionContext = createContext<ContextProps>([
  { ...defValue, dialog: defDialog },
  () => {},
  () => {},
])

export const PermissionProvider = (props: ProviderProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { children } = props
  const [permissions, setPermissions] = useState<PermissionState>(() => ({ camera: '', geolocation: '' }))
  const [dialog, setDialog] = useState<DialogState>(() => null)

  useEffect(() => {
    const checkPermissions = async () => {
      if (!navigator.permissions) {
        enqueueSnackbar('El navegador no es compatible', { variant: 'info' })
        return
      }
      const camera = await navigator.permissions.query({ name: 'camera' })
      const geolocation = await navigator.permissions.query({ name: 'geolocation' })

      setPermissions({
        camera: camera.state,
        geolocation: geolocation.state,
      })
    }

    checkPermissions()
  }, [enqueueSnackbar])

  return (
    <React.Fragment>
      <PermissionContext.Provider value={[{ ...permissions, dialog }, setPermissions, setDialog]}>
        {children}
        <PermissionDialog />
      </PermissionContext.Provider>
    </React.Fragment>
  )
}
