import { useMediaQuery, useTheme } from '@material-ui/core'
import { createContext, Dispatch, memo, ReactNode, SetStateAction, useEffect, useState } from 'react'
import detectBrowserLanguage from 'detect-browser-language'

export type LangType = 'es' | 'en'
interface StateProps {
  lang: LangType
  isMobile?: boolean
  cameraOpened?: boolean
  hasScrolled?: boolean
}

interface ProviderProps {
  children: ReactNode
}

const defValue: StateProps = {
  lang: 'es',
  isMobile: undefined,
  cameraOpened: undefined,
  hasScrolled: false,
}

type ContextProps = [StateProps, Dispatch<SetStateAction<StateProps>>]

export const LangContext = createContext<ContextProps>([defValue, () => {}])

export const LangProvider = memo(({ children }: ProviderProps) => {
  const [config, setConfig] = useState<StateProps>(() => {
    const fromStorage = localStorage.getItem('lang')
    if (fromStorage === null) {
      const detected = detectBrowserLanguage()
      return { ...defValue, lang: detected === 'es' ? 'es' : detected === 'en' ? 'en' : 'en' }
    }

    return { ...defValue, lang: fromStorage as LangType }
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setConfig((act) => ({ ...act, isMobile }))
  }, [isMobile])

  return <LangContext.Provider value={[config, setConfig]}>{children}</LangContext.Provider>
})
