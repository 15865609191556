import { Collapse, Fab, makeStyles } from '@material-ui/core'
import React, { SetStateAction } from 'react'
import { FaArrowLeft, FaBars, FaPlus } from 'react-icons/fa'
import { useHistory, useLocation } from 'react-router-dom'
import useLang, { useCamera, useResponsive, useScroll } from '../hooks/useLang'
import { ReactComponent as UK } from '../assets/icons/uk.svg'
import { ReactComponent as Spain } from '../assets/icons/spain.svg'

interface Props {
  open: boolean
  setOpen: React.Dispatch<SetStateAction<boolean>>
}

const FabIcons = ({ open, setOpen }: Props) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const classes = useClasses()
  const isMobile = useResponsive()
  const { commons, lang, setLang } = useLang()

  const { isOpen, setOpened } = useCamera()
  const isAdmin = pathname.includes('admin')
  const isNewSample = pathname.includes('new_sample')
  const isMap = pathname.includes('map')
  const isDetail = pathname.includes('detail')
  const { hasScrolled } = useScroll()

  if (isAdmin) return null

  return (
    <React.Fragment>
      <Collapse in={!hasScrolled && isMobile} timeout="auto">
        <Fab
          className={classes.langIcon}
          onClick={() => {
            setLang(lang === 'es' ? 'en' : 'es')
          }}>
          {lang === 'es' ? <UK width={25} height={25} /> : <Spain width={25} height={25} />}
          {/* {commons.general.changeLanguage[lang === 'en' ? 'es' : 'en']}
        <div style={{ display: 'flex', marginLeft: 8 }}>
        {lang === 'es' ? <UK width={25} height={25} /> : <Spain width={25} height={25} />}
      </div> */}
        </Fab>
      </Collapse>
      <Collapse in={isMobile || isMap || isNewSample} timeout="auto">
        <div className={classes.fabIcon}>
          <Fab
            color="secondary"
            onClick={() => {
              if (isOpen) {
                setOpened(false)
                return
              }
              // history.push('/')
              if (isNewSample) history.goBack()
              else if (isDetail) history.goBack()
              else {
                setOpen((act) => !act)
              }
            }}>
            {open || isDetail || isNewSample ? <FaArrowLeft /> : <FaBars />}
          </Fab>
        </div>
      </Collapse>
      <Collapse in={!isNewSample} timeout={500}>
        <div className={classes.fabExtended}>
          <Fab
            color="primary"
            variant={!isMobile ? 'extended' : undefined}
            onClick={() => {
              setOpen(false)
              history.push('/new_sample')
            }}>
            {isMobile ? (
              <FaPlus />
            ) : (
              <div>
                <FaPlus className={classes.extended} />
                {commons.formSample.newSample[lang]}
              </div>
            )}
          </Fab>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

const useClasses = makeStyles((theme) => ({
  fabIcon: {
    position: 'fixed',
    bottom: 10,
    left: 10,
    zIndex: theme.zIndex.drawer + 1,
  },
  extended: {
    marginRight: theme.spacing(1),
  },
  fabExtended: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    zIndex: theme.zIndex.drawer + 1,
  },
  langIcon: {
    position: 'fixed',
    right: 10,
    top: 10,
    zIndex: theme.zIndex.drawer + 1,
  },
}))

export default FabIcons
