import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'

const current = createMuiTheme({})
const blancoTrans = 'rgba(255,255,255,0.8)'

const Theme = {
  colores: {
    blancoTrans,
    aqua: '#3f86bd',
  },
  current,
}

export default Theme
