import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { memo, ReactNode, SetStateAction, useEffect, useMemo } from 'react'
import {
  FaArtstation,
  FaBook,
  FaGlobeAmericas,
  FaHandHoldingHeart,
  FaHome,
  FaInfo,
  FaMap,
  FaNewspaper,
  FaWater,
} from 'react-icons/fa'
import { useHistory, useLocation } from 'react-router-dom'
import useLang, { commons, useResponsive } from '../hooks/useLang'
import Logo from '../assets/images/logocolor.png'
import Social from './Social'
import { RoutesProps } from '../routes/Main'
import Theme from '../utils/Theme'
import { ReactComponent as UK } from '../assets/icons/uk.svg'
import { ReactComponent as Spain } from '../assets/icons/spain.svg'

interface OptionsProps {
  id: keyof typeof commons.menu
  icon: ReactNode
  onClick?: () => void
}

interface Props {
  open: boolean
  setOpen: React.Dispatch<SetStateAction<boolean>>
  routes: RoutesProps
}

const MainMenu = memo(({ open, setOpen, routes }: Props) => {
  const classes = useClasses({ Logo })
  const { lang, commons, setLang } = useLang()
  const { pathname } = useLocation()
  const history = useHistory()
  const isMobile = useResponsive()

  const options = useMemo(
    (): OptionsProps[] => [
      { id: 'home', icon: <FaHome /> },
      { id: 'newSample', icon: <FaWater /> },
      {
        id: 'map',
        icon: <FaMap />,
        onClick: () => {
          setOpen(false)
        },
      },
      { id: 'exhibitions', icon: <FaBook /> },
      { id: 'news', icon: <FaNewspaper /> },
      { id: 'about', icon: <FaInfo /> },
      { id: 'joinUs', icon: <FaGlobeAmericas /> },
      { id: 'aliances', icon: <FaHandHoldingHeart /> },
      {
        id: 'aboutArtist',
        icon: <FaArtstation />,
        onClick: () => {
          window.open('https://www.cmontani.com/', '_blank')
        },
      },
    ],
    [setOpen],
  )

  useEffect(() => {
    if (isMobile === undefined) return
    if (pathname.includes('map') || pathname.includes('new_sample') || pathname.includes('admin'))
      setOpen(false)
    else setOpen(!isMobile)
  }, [isMobile, setOpen, pathname])

  return (
    <Drawer classes={{ paper: classes.root }} variant="persistent" anchor="left" open={open}>
      <div className={classes.logo} onClick={() => history.replace('/')} />
      <List component="nav" dense>
        {options.map(({ id, icon, onClick }) => (
          <ListItem
            key={id}
            button
            selected={pathname === routes[id]?.path}
            onClick={() => {
              if (onClick && !routes[id]) onClick()
              if (routes[id]) history.push(routes[id].path)
              if (routes[id] && onClick) onClick()
              if (isMobile) setOpen(false)
            }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={commons.menu[id][lang]} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className={classes.contact}>
        <Tooltip title={commons.mainMenu.mail[lang]}>
          <Typography>
            <a className={classes.links} href="mailto:info@cmontani.com">
              📧 <span className={classes.textos}>info@cmontani.com</span>
            </a>
          </Typography>
        </Tooltip>
        <Tooltip title={commons.mainMenu.call[lang]}>
          <Typography className={classes.sep}>
            <a className={classes.links} href="tel:+5491162629083">
              📞 <span className={classes.textos}>+54 911 6262 9083</span>
            </a>
          </Typography>
        </Tooltip>
      </div>
      <Social />
      <div className={classes.language}>
        {!isMobile && (
          <Button
            onClick={() => {
              setLang(lang === 'es' ? 'en' : 'es')
            }}
            variant="text">
            {commons.general.changeLanguage[lang === 'en' ? 'es' : 'en']}
            <div style={{ display: 'flex', marginLeft: 8 }}>
              {lang === 'es' ? <UK width={25} height={25} /> : <Spain width={25} height={25} />}
            </div>
          </Button>
        )}
      </div>
    </Drawer>
  )
})

type StyleType = Partial<{ Logo: string }>

const useClasses = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: 280,
      backgroundColor: Theme.colores.blancoTrans,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.background.paper,
    },
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  logo: ({ Logo }: StyleType) => ({
    margin: theme.spacing(2),
    height: 140,
    backgroundImage: `url(${Logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  }),
  contact: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  sep: {
    marginTop: theme.spacing(1),
    textDecoration: 'none',
  },
  links: {
    textDecoration: 'none',
  },
  textos: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  language: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: 1,
    marginBottom: theme.spacing(2),
  },
  langLink: {
    cursor: 'pointer',
    userSelect: 'none',
  },
}))

export default MainMenu
