const Llaves = {
  Usuario: 'Usuario',
  ultimaBusqueda: 'ultimaBusqueda',
}

type StorageKeys = keyof typeof Llaves

const getItem = <T extends any = {}>(llave: StorageKeys): T | null => {
  const enstring = localStorage.getItem(llave)
  if (enstring) {
    return JSON.parse(enstring)
  } else {
    return null
  }
}

const saveItem = <T extends object = {}>(llave: StorageKeys, data: T): object => {
  const enstring = JSON.stringify(data)
  localStorage.setItem(llave, enstring)
  return data
}

const removeItem = (key: StorageKeys) => {
  localStorage.removeItem(key)
}
const storage = {
  getItem,
  saveItem,
  removeItem,
}
export default storage
