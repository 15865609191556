import { AxiosRequestConfig } from 'axios'
import {
  ErrorResponse,
  useAxiosAr as useAxiosArMat,
  UseAxiosProps as UseAxiosPropsMat,
} from 'material-crud'
import { OptionsObject, useSnackbar } from 'notistack'
import { ReactNode, ReactText, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'

interface Error {
  message: string
  code: number
}

interface CallResponse<T = any> {
  error?: ErrorResponse
  response?: T
  status?: number
}

type Enque = (message: ReactNode, options?: OptionsObject | undefined) => ReactText
export const showError = (enqueueSnackbar: Enque, onExired?: () => void) => (err: Error) => {
  if (err.code >= 300 && err.code < 400) {
    setTimeout(() => {
      if (onExired) onExired()
    }, 500)
  }
  enqueueSnackbar(`${err.message} (${err.code})`, { variant: 'error' })
}

export type CallProps<T> = (config?: AxiosRequestConfig) => Promise<CallResponse<T>>
type UseAxiosRet<T> = [
  T | undefined,
  boolean,
  CallProps<T>,
  () => void,
  number | undefined,
  ErrorResponse | undefined,
]

const useAxios = <T extends any = any>(props?: UseAxiosPropsMat): UseAxiosRet<T> => {
  const lastCallRef = useRef<AxiosRequestConfig | undefined>(props?.onInit)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [response, loading, call, clean, status, error] = useAxiosArMat<T>(
    props
      ? { ...props, onError: showError(enqueueSnackbar, () => history.replace('/admin')) }
      : { onError: showError(enqueueSnackbar, () => history.replace('/admin')) },
  )

  const callNoConfig = useCallback<CallProps<T>>(
    async (config) => {
      if (!config && lastCallRef.current) {
        return call(lastCallRef.current)
      }
      if (config) {
        lastCallRef.current = config
        return call(config)
      }
      throw Error('No se esta llamando bien el call useAxios')
    },
    [call],
  )

  return [response, loading, callNoConfig, clean, status, error]
}

export default useAxios
