import Config from './Config'

const apiUrl = Config.BASE_URL + 'api/'
const imagesUrl = Config.BASE_URL + 'archivos/muestras/'
const imgExhibitionUrl = Config.BASE_URL + 'archivos/exibiciones/'
const imgNewsUrl = Config.BASE_URL + 'archivos/noticias/'

const Urls = {
  imagesUrl,
  imgExhibitionUrl,
  imgNewsUrl,
  muestras: `${apiUrl}muestra/`,
  exportar: `${apiUrl}muestra/exportar`,
  muestrasTotal: `${apiUrl}muestra/total`,

  exhibitions: `${apiUrl}exhibition`,
  news: `${apiUrl}news`,
  users: `${apiUrl}users`,

  deleteImage: `${apiUrl}images`,

  paises: 'https://parseapi.back4app.com/classes/Continentscountriescities_Country',
  ciudades: 'https://parseapi.back4app.com/classes/Continentscountriescities_City',
  paisesGuardados: `${apiUrl}muestra/getPaises`,
  geocode: `${apiUrl}muestra/geocode`,

  login: `${apiUrl}auth/login`,
  changePass: `${apiUrl}auth/changePassword`,
}

export default Urls
