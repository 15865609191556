import React, { useMemo } from 'react'
import { ReactComponent as Facebook } from '../assets/icons/facebook.svg'
import { ReactComponent as Instagram } from '../assets/icons/instagram.svg'
import { ReactComponent as Twitter } from '../assets/icons/twitter.svg'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import useLang from '../hooks/useLang'

interface MediaProps {
  Icono: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>
  link: string
  key: 'facebook' | 'twitter' | 'instagram'
}

const Social = () => {
  const { commons, lang } = useLang()
  const media = useMemo<MediaProps[]>(
    () => [
      { Icono: Facebook, link: 'https://www.facebook.com/AquaPlanetae', key: 'facebook' },
      { Icono: Twitter, link: 'https://twitter.com/AquaPlanetae', key: 'twitter' },
      { Icono: Instagram, link: 'https://www.instagram.com/aqua.planetae/?igshid=1x749fmvpy36g', key: 'instagram' },
    ],
    [],
  )

  const classes = useClasses()

  return (
    <div className={classes.root}>
      {media.map(({ Icono, link, key }) => (
        <Tooltip key={link} title={commons.mainMenu[key][lang]}>
          <IconButton
            onClick={() => {
              window.open(link, '_blank')
            }}>
            <Icono width={32} height={32} />
          </IconButton>
        </Tooltip>
      ))}
    </div>
  )
}

const useClasses = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default Social
