import { CircularProgress, makeStyles } from '@material-ui/core'
import { useUser } from 'material-crud'
import React, { ComponentType, lazy, Suspense, useCallback, useMemo, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Background from '../assets/images/background.png'
import FabIcons from '../components/FabIcons'
import MainMenu from '../components/MainMenu'
import { useResponsive } from '../hooks/useLang'
import { ServicesProvider } from '../utils/ServicesContext'

const Home = lazy(() => import('../screens/Home'))
const About = lazy(() => import('../screens/About'))
const MapScreen = lazy(() => import('../screens/Map'))
const Aliances = lazy(() => import('../screens/Aliances'))
const JoinUs = lazy(() => import('../screens/JoinUs'))
const NewSample = lazy(() => import('../screens/NewSample'))
const PrivacyPolicy = lazy(() => import('../screens/PrivacyPolicy'))
const ByYearScreen = lazy(() => import('../screens/ByYearScreen'))
const ExhibitionsDetail = lazy(() => import('../screens/ExhibitionsDetail'))
const NewsDetail = lazy(() => import('../screens/NewsDetail'))
const Login = lazy(() => import('../screens/Login'))
const Admin = lazy(() => import('../screens/Admin'))
const ExhibitionAdmin = lazy(() => import('../screens/admin/ExhibitionAdmin'))
const NewsAdmin = lazy(() => import('../screens/admin/NewsAdmin'))
const SamplesAdmin = lazy(() => import('../screens/admin/SamplesAdmin'))
const UsersAdmin = lazy(() => import('../screens/admin/UsersAdmin'))

export type RoutesProps = {
  [key: string]: {
    path: string
    Screen: any //React.LazyExoticComponent<(props?: any) => JSX.Element | null> | JSX.Element
    exact?: boolean
  }
}

const Main = () => {
  const [open, setOpen] = useState(false)
  const isMobile = useResponsive()
  const classes = useClasses({ open, isMobile })
  const { user } = useUser()

  const routes = useMemo(
    (): RoutesProps => ({
      home: { path: '/', Screen: Home },
      about: { path: '/about', Screen: About },
      map: { path: '/map', Screen: MapScreen, exact: true },
      // news: { path: '/news', Screen: News },
      joinUs: { path: '/join_us', Screen: JoinUs },
      aliances: { path: '/aliances', Screen: Aliances },
      newSample: { path: '/new_sample', Screen: NewSample },
      policyPrivacy: { path: '/policy', Screen: PrivacyPolicy },
      exhibitions: { path: '/exhibitions', Screen: <ByYearScreen type="exhibitions" />, exact: true },
      news: { path: '/news', Screen: <ByYearScreen type="news" />, exact: true },
      exhibitionsDetail: { path: '/exhibitions/detail', Screen: ExhibitionsDetail },
      newsDetail: { path: '/news/detail', Screen: NewsDetail },

      login: { path: '/admin', Screen: !user ? Login : Admin, exact: true },
      exhibitionAdmin: { path: '/admin/exhibition', Screen: ExhibitionAdmin },
      newsAdmin: { path: '/admin/news', Screen: NewsAdmin },
      samplesAdmin: { path: '/admin/samples', Screen: SamplesAdmin },
      usersAdmin: { path: '/admin/users', Screen: UsersAdmin },
    }),
    [user],
  )

  const renderRoute = useCallback(() => {
    return Object.keys(routes).map((key) => {
      const { Screen, path, exact } = routes[key]
      if (path === '/news' || path === '/exhibitions')
        return (
          <Route key={path} path={path} exact={exact}>
            {Screen}
          </Route>
        )

      return (
        <Route key={path} path={path} exact={exact || path === '/'} component={Screen as ComponentType} />
      )
    })
  }, [routes])

  return (
    <div className={classes.root}>
      <BrowserRouter>
        <ServicesProvider>
          <MainMenu open={open} setOpen={setOpen} routes={routes} />
          <main className={classes.content}>
            <FabIcons open={open} setOpen={setOpen} />
            <Switch>
              <Suspense fallback={<CircularProgress color="primary" />}>{renderRoute()}</Suspense>
            </Switch>
          </main>
        </ServicesProvider>
      </BrowserRouter>
    </div>
  )
}

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundImage: `url(${Background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  content: ({ open, isMobile }: any) => ({
    marginLeft: isMobile ? 0 : open ? 280 : 0,
    width: '100%',
  }),
}))

export default Main
