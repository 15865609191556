const { REACT_APP_BASE_URL, REACT_APP_GOOGLE_MAP, REACT_APP_OPEN_MAP, REACT_APP_HERE_MAP } = process.env

const Config = {
  NAME: 'AQUA PLANETAE',
  BASE_URL: REACT_APP_BASE_URL,
  GOOGLE_KEY: REACT_APP_GOOGLE_MAP,
  OPEN_MAP_KEY: REACT_APP_OPEN_MAP,
  MAPHERE_KEY: REACT_APP_HERE_MAP as string,
}

export default Config
