import { CssBaseline, ThemeProvider } from '@material-ui/core'
import React, { useState } from 'react'
import Main from './routes/Main'
import { LangProvider } from './utils/LangContext'
import { PermissionProvider } from './utils/PermissionContext'
import { SnackbarProvider } from 'notistack'
import Theme from './utils/Theme'
import firebase from 'firebase'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import useLang from './hooks/useLang'
import { CrudProvider, esAR } from 'material-crud'
import Storage from './utils/Storage'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

firebase.initializeApp({
  apiKey: 'AIzaSyCJkZLDL7UKEFKjJfhJ24PngluY7kM8Nc4',
  authDomain: 'aqua-planetae.firebaseapp.com',
  projectId: 'aqua-planetae',
  storageBucket: 'aqua-planetae.appspot.com',
  messagingSenderId: '296640982607',
  appId: '1:296640982607:web:da18efd05c9261c11bc43a',
})

export interface UsuarioProps {
  usuario: string
  token: string
  nombre?: string
  apellido?: string
  activo?: boolean
}

const InternalApp = () => {
  const { lang } = useLang()
  const [user, setUser] = useState(Storage.getItem<UsuarioProps>('Usuario'))
  return (
    <CrudProvider
      user={user}
      headers={user ? { authorization: `Bearer ${user.token}` } : undefined}
      onUser={(newUser) => {
        setUser(newUser)
        if (newUser) Storage.saveItem('Usuario', newUser)
        else Storage.removeItem('Usuario')
      }}
      lang={{ ...esAR, crudCol: '' }}>
      <PermissionProvider>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={lang}>
          <Main />
        </MuiPickersUtilsProvider>
      </PermissionProvider>
    </CrudProvider>
  )
}

const App = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={Theme.current}>
        <SnackbarProvider>
          <LangProvider>
            <InternalApp />
          </LangProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App
